import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/form/:formname',
    name: 'Form Page',
    component: () => import('../views/FormPage.vue')
  },
  {
    path: '/pay/:formname',
    name: 'Pay Page',
    component: () => import('../views/PayPage.vue')
  },
  {
    path: '/page/:pagename',
    name: 'Page',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/confirmEmail/:token',
    name: 'Confirm Email',
    component: () => import('../pages/ConfirmEmail.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
